// Transparent background and border properties included for button version.
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

.BTN-close {
  box-sizing: content-box;
  width: $BTN-close-width;
  height: $BTN-close-height;
  padding: $BTN-close-padding-y $BTN-close-padding-x;
  color: $BTN-close-color;
  background: transparent escape-svg($BTN-close-bg) center / $BTN-close-width auto no-repeat; // include transparent for button elements
  border: 0; // for button elements
  @include border-radius();
  opacity: $BTN-close-opacity;

  // Override <a>'s hover style
  &:hover {
    color: $BTN-close-color;
    text-decoration: none;
    opacity: $BTN-close-hover-opacity;
  }

  &:focus {
    outline: 0;
    box-shadow: $BTN-close-focus-shadow;
    opacity: $BTN-close-focus-opacity;
  }

  &:disabled,
  &.disabled {
    pointer-events: none;
    user-select: none;
    opacity: $BTN-close-disabled-opacity;
  }
}

.BTN-close-white {
  filter: $BTN-close-white-filter;
}
