//
// Base styles
//

.BTN {
  // scss-docs-start BTN-css-vars
  --#{$prefix}BTN-padding-x: #{$BTN-padding-x};
  --#{$prefix}BTN-padding-y: #{$BTN-padding-y};
  --#{$prefix}BTN-font-family: #{$BTN-font-family};
  @include rfs($BTN-font-size, --#{$prefix}BTN-font-size);
  --#{$prefix}BTN-font-weight: #{$BTN-font-weight};
  --#{$prefix}BTN-line-height: #{$BTN-line-height};
  --#{$prefix}BTN-color: #{$body-color};
  --#{$prefix}BTN-bg: transparent;
  --#{$prefix}BTN-border-width: #{$BTN-border-width};
  --#{$prefix}BTN-border-color: transparent;
  --#{$prefix}BTN-border-radius: #{$BTN-border-radius};
  --#{$prefix}BTN-box-shadow: #{$BTN-box-shadow};
  --#{$prefix}BTN-disabled-opacity: #{$BTN-disabled-opacity};
  --#{$prefix}BTN-focus-box-shadow: 0 0 0 #{$BTN-focus-width} rgba(var(--#{$prefix}BTN-focus-shadow-rgb), .5);
  // scss-docs-end BTN-css-vars

  display: inline-block;
  padding: var(--#{$prefix}BTN-padding-y) var(--#{$prefix}BTN-padding-x);
  font-family: var(--#{$prefix}BTN-font-family);
  @include font-size(var(--#{$prefix}BTN-font-size));
  font-weight: var(--#{$prefix}BTN-font-weight);
  line-height: var(--#{$prefix}BTN-line-height);
  color: var(--#{$prefix}BTN-color);
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $BTN-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  border: var(--#{$prefix}BTN-border-width) solid var(--#{$prefix}BTN-border-color);
  @include border-radius(var(--#{$prefix}BTN-border-radius));
  @include gradient-bg(var(--#{$prefix}BTN-bg));
  @include box-shadow(var(--#{$prefix}BTN-box-shadow));
  @include transition($BTN-transition);

  &:hover {
    color: var(--#{$prefix}BTN-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: var(--#{$prefix}BTN-hover-bg);
    border-color: var(--#{$prefix}BTN-hover-border-color);
  }

  .BTN-check:focus + &,
  &:focus {
    color: var(--#{$prefix}BTN-hover-color);
    @include gradient-bg(var(--#{$prefix}BTN-hover-bg));
    border-color: var(--#{$prefix}BTN-hover-border-color);
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}BTN-box-shadow), var(--#{$prefix}BTN-focus-box-shadow);
    } @else {
      box-shadow: var(--#{$prefix}BTN-focus-box-shadow);
    }
  }

  .BTN-check:checked + &,
  .BTN-check:active + &,
  &:active,
  &.active,
  &.show {
    color: var(--#{$prefix}BTN-active-color);
    background-color: var(--#{$prefix}BTN-active-bg);
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}BTN-active-border-color);
    @include box-shadow(var(--#{$prefix}BTN-active-shadow));

    &:focus {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: var(--#{$prefix}BTN-active-shadow), var(--#{$prefix}BTN-focus-box-shadow);
      } @else {
        box-shadow: var(--#{$prefix}BTN-focus-box-shadow);
      }
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: var(--#{$prefix}BTN-disabled-color);
    pointer-events: none;
    background-color: var(--#{$prefix}BTN-disabled-bg);
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}BTN-disabled-border-color);
    opacity: var(--#{$prefix}BTN-disabled-opacity);
    @include box-shadow(none);
  }
}


//
// Alternate buttons
//

// scss-docs-start BTN-variant-loops
@each $color, $value in $theme-colors {
  .BTN-#{$color} {
    @if $color == "light" {
      @include button-variant(
        $value,
        $value,
        $hover-background: shade-color($value, $BTN-hover-bg-shade-amount),
        $hover-border: shade-color($value, $BTN-hover-border-shade-amount),
        $active-background: shade-color($value, $BTN-active-bg-shade-amount),
        $active-border: shade-color($value, $BTN-active-border-shade-amount)
      );
    } @else if $color == "dark" {
      @include button-variant(
        $value,
        $value,
        $hover-background: tint-color($value, $BTN-hover-bg-tint-amount),
        $hover-border: tint-color($value, $BTN-hover-border-tint-amount),
        $active-background: tint-color($value, $BTN-active-bg-tint-amount),
        $active-border: tint-color($value, $BTN-active-border-tint-amount)
      );
    } @else {
      @include button-variant($value, $value);
    }
  }
}

@each $color, $value in $theme-colors {
  .BTN-outline-#{$color} {
    @include button-outline-variant($value);
  }
}
// scss-docs-end BTN-variant-loops


//
// Link buttons
//

// Make a button look and behave like a link
.BTN-link {
  --#{$prefix}BTN-font-weight: #{$font-weight-normal};
  --#{$prefix}BTN-color: #{$BTN-link-color};
  --#{$prefix}BTN-bg: transparent;
  --#{$prefix}BTN-border-color: transparent;
  --#{$prefix}BTN-hover-color: #{$BTN-link-hover-color};
  --#{$prefix}BTN-hover-border-color: transparent;
  --#{$prefix}BTN-active-color: #{$BTN-link-hover-color};
  --#{$prefix}BTN-active-border-color: transparent;
  --#{$prefix}BTN-disabled-color: #{$BTN-link-disabled-color};
  --#{$prefix}BTN-disabled-border-color: transparent;
  --#{$prefix}BTN-box-shadow: none;
  --#{$prefix}BTN-focus-shadow-rgb: #{to-rgb(mix(color-contrast($primary), $primary, 15%))};

  text-decoration: $link-decoration;

  &:hover,
  &:focus {
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    color: var(--#{$prefix}BTN-color);
  }

  &:hover {
    color: var(--#{$prefix}BTN-hover-color);
  }

  // No need for an active state here
}


//
// Button Sizes
//

.BTN-lg {
  @include button-size($BTN-padding-y-lg, $BTN-padding-x-lg, $BTN-font-size-lg, $BTN-border-radius-lg);
}

.BTN-sm {
  @include button-size($BTN-padding-y-sm, $BTN-padding-x-sm, $BTN-font-size-sm, $BTN-border-radius-sm);
}
